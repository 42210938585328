import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://84f070e57ea4a6134b7ababc79944115@o503027.ingest.us.sentry.io/4508162955083776',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { useNavigate } from '@reach/router';
import heic2any from 'heic2any';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Loader } from '../../components/Loader';
import { IMainContainerProps } from './MainContainer.types';
import { base64ToBlob } from './MainContainer.helpers';
import { StyledWrapper } from './MainContainer.styles';
import * as Sentry from '@sentry/react';

interface QueryStringParams {
	secret?: string;
	downloadReportLink?: string;
}

const MainContainer: React.FC<IMainContainerProps> = ({ location }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentBlobUrl, setCurrentBlobUrl] = useState<string>('');

	useEffect(() => {
		const { secret, downloadReportLink } = queryString.parse(
			location.search
		) as QueryStringParams;

		try {
			if (downloadReportLink) {
				handleFileDownload(downloadReportLink);
				return;
			}

			if (secret) {
				handleSecret(secret);
			} else {
				Sentry.withScope((scope) => {
					scope.setTag('missing_secret_and_download_link', true);
					scope.setContext('query_params', { locationSearch: location.search });
					Sentry.captureMessage(
						'Missing secret and download link in query params',
						Sentry.Severity.Critical
					);
				});
				navigate('/error');
			}
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('query_params', { locationSearch: location.search });
				Sentry.captureException(err);
			});
			navigate('/error');
		}
	}, [location.search, navigate]);

	const handleFileDownload = (downloadLink: string) => {
		try {
			const linkElement: HTMLAnchorElement = document.createElement('a');
			linkElement.href = downloadLink;
			linkElement.click();
			setTimeout(() => {
				window.close();
			}, 2000);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('download_link', { downloadLink });
				Sentry.captureException(err);
			});
		}
	};

	const handleSecret = async (secret: string) => {
		try {
			// Clean up the secret by replacing spaces with '+', removing all whitespace, and decode the base64 string
			let fileLink: string = atob(secret.replace(/ /g, '+').replace(/\s/g, ''));
			await fetchAndRenderFile(fileLink);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('decoded_secret', { secret });
				Sentry.captureException(err);
			});
			navigate('/error');
		}
	};

	const fetchAndRenderFile = async (url: string) => {
		try {
			setIsLoading(true);
			let isGenerated: boolean =
				url.includes('medicheck-backend') ||
				url.includes('api-ext.medicheck.io');

			if (isGenerated) {
				url = url.replace(
					'https://medicheck-backend.azurewebsites.net',
					'https://api-ext.medicheck.io'
				);
			}

			const response: AxiosResponse<any> = await axios.get(url, {
				headers: {
					'Content-Type': 'application/octet-stream',
					Accept: 'application/octet-stream',
				},
				responseType: !isGenerated ? 'arraybuffer' : undefined,
			});

			if (response.data === '') {
				Sentry.withScope((scope) => {
					scope.setTag('empty_response', true);
					scope.setContext('fetch_file', { url });
					Sentry.captureMessage(
						'Empty response data from file fetch',
						Sentry.Severity.Error
					);
				});
				navigate('/error');
				return;
			}

			const blob: Blob = base64ToBlob(
				Buffer.from(response.data, 'binary').toString('base64')
			);
			let localDataUrl: string = URL.createObjectURL(blob);
			const urlWithoutParams: string = url.split('?')[0];

			if (isImageFormat(urlWithoutParams)) {
				await handleImageRendering(response.data, url);
			} else if (urlWithoutParams.toLowerCase().endsWith('.pdf')) {
				handlePdfRendering(response.data);
			} else {
				handleFallbackRendering(response.data, isGenerated);
			}
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('fetch_file', { url });
				Sentry.captureException(err);
			});
			navigate('/error');
		} finally {
			setIsLoading(false);
		}
	};

	const isImageFormat = (url: string): boolean => {
		return (
			url.toLowerCase().endsWith('.jpg') ||
			url.toLowerCase().endsWith('.png') ||
			url.toLowerCase().endsWith('.jpeg') ||
			url.toLowerCase().endsWith('.heic')
		);
	};

	const handleImageRendering = async (
		arrayBuffer: ArrayBuffer,
		url: string
	) => {
		try {
			const img: HTMLImageElement = new Image();
			img.crossOrigin = 'anonymous';
			img.src = url;

			if (url.toLowerCase().endsWith('.heic')) {
				const jpegBuffer = await heic2any({
					blob: new Blob([arrayBuffer], { type: 'image/heic' }),
					toType: 'image/jpeg',
				});
				const blob: Blob =
					jpegBuffer instanceof Blob ? jpegBuffer : jpegBuffer[0];
				img.src = URL.createObjectURL(blob);
			}

			img.onload = () => {
				try {
					const canvas: HTMLCanvasElement = document.createElement('canvas');
					const context = canvas.getContext('2d');
					if (!context) throw new Error('Failed to get canvas context');
					const XratioW: number = img.width / 595;
					const XratioH: number = img.height / 804;

					canvas.width = img.width / XratioW;
					canvas.height = img.height / XratioH;
					context.drawImage(
						img,
						0,
						0,
						img.width / XratioW,
						img.height / XratioH
					);

					const docDefinition: any = {
						content: [
							{
								image: canvas.toDataURL('image/jpeg', 1.0),
								width: img.width / XratioW,
								height: img.height / XratioH,
								alignment: 'center',
							},
						],
					};

					const pdfDocGenerator = pdfMake.createPdf(docDefinition);
					pdfDocGenerator.getBlob((blob) => {
						setCurrentBlobUrl(URL.createObjectURL(blob));
					});
				} catch (err) {
					Sentry.withScope((scope) => {
						scope.setLevel(Sentry.Severity.Error);
						scope.setContext('image_rendering', { imgSrc: img.src });
						Sentry.captureException(err);
					});
				}
			};
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('image_rendering', { arrayBuffer, url });
				Sentry.captureException(err);
			});
			navigate('/error');
		}
	};

	const handlePdfRendering = (data: any) => {
		try {
			const blob: Blob = new Blob([data], { type: 'application/pdf' });
			setCurrentBlobUrl(URL.createObjectURL(blob));
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('pdf_rendering', { data });
				Sentry.captureException(err);
			});
		}
	};

	const handleFallbackRendering = (data: any, isGenerated: boolean) => {
		try {
			let localDataUrl: string = '';
			if (!isGenerated) {
				localDataUrl = Buffer.from(data, 'binary').toString('base64');
			} else {
				localDataUrl = data;
			}
			setCurrentBlobUrl('data:application/pdf;base64,' + localDataUrl);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel(Sentry.Severity.Error);
				scope.setContext('fallback_rendering', { data, isGenerated });
				Sentry.captureException(err);
			});
		}
	};

	useEffect(() => {
		console.log('currentBlobUrl', currentBlobUrl);
	}, [currentBlobUrl]);

	return (
		<StyledWrapper>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
				}}
			>
				{isLoading && <Loader />}
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						height: '100%',
					}}
				>
					{!!currentBlobUrl && (
						<object
							data={currentBlobUrl}
							type={'application/pdf'}
							width='100%'
							height='100%'
						/>
					)}
				</div>
			</div>
		</StyledWrapper>
	);
};

export default MainContainer;

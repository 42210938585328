import React from 'react';

import { Router } from '@reach/router';

import { ErrorContainer } from './containers/ErrorContainer';
import { MainContainer } from './containers/MainContainer';
import { ReportContainer } from './containers/ReportContainer';

const App = () => {
  console.log('v1.0.1');
  return (
    <Router>
      <MainContainer path='/' />
      <ReportContainer path='/report' />
      <ErrorContainer path='/error' default />
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { useNavigate } from '@reach/router';

import { IReportContainerProps } from './ReportContainer.types';
interface IQueryString {
	secret?: string;
	downloadReportLink?: string;
}

const ReportContainer = ({ location }: IReportContainerProps) => {
	const navigate = useNavigate();
	const parsed: IQueryString = queryString.parse(location.search);
	const [decodedSecret, setDecodedSecret] = useState('');

	//Function to decode b64 to utf8 & handle special characters
	function b64_to_utf8(str: string) {
		return decodeURIComponent(escape(window.atob(str)));
	}

	useEffect(() => {
		if (!parsed.secret && !parsed.downloadReportLink) {
			navigate('/error');
		}

		if (parsed.secret) {
			try {
				let fileLink = b64_to_utf8(parsed.secret);
				setDecodedSecret(fileLink);
			} catch (err) {
				alert('Error while downloading file');
				navigate('/error');
			}
		}
	}, [parsed.secret, navigate]);

	useEffect(() => {
		async function call(url: string) {
			try {
				const isGenerated = url.includes('medicheck-backend');
				url = url.replace(
					'https://medicheck-backend.azurewebsites.net',
					'https://api-ext.medicheck.io'
				);
				const response = await axios.get(url, {
					headers: {
						'Content-Type': 'application/octet-stream',
						Accept: 'application/octet-stream',
					},
					responseType: !isGenerated ? 'arraybuffer' : undefined,
				});
				if (response.data === '') {
					navigate('/error');
					return;
				}

				if (
					url?.toLowerCase().includes('.csv') ||
					url?.toLowerCase().includes('.xlsx')
				) {
					window.open(decodedSecret, '_self');
					alert('Downloaded file');
				}
			} catch (err) {
				alert('Error while downloading file');
				navigate('/error');
			}
		}
		if (decodedSecret) {
			call(decodedSecret);
		}
	}, [decodedSecret, navigate]);

	return <div />;
};

export default ReportContainer;
